export const defaultAppLoginTitle = "心理测评系统 3.0";

export const nodeTypes = {
  super: {
    value: "super"
  },
  client: {
    value: "client"
  },
  dept: {
    value: "dept"
  }
};

export const personInputTypes = {
  search: {
    value: "search",
    text: "登录界面"
  },
  create: {
    value: "create",
    text: "新建用户界面"
  }
};
