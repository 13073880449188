import { getRolePermissionFromUser } from "@/api/role";
import {
  isClientTypeHospital,
  isClientTypeJudicial,
  isClientTypeSchool
} from "@/utils/appType";

export function getDefaultNaviTitle() {
  let defaultNaviTitle = {
    chartBox: "数据看板",
    caseList: "案例",
    personList: "被试人员",
    node: "测评单位",
    lbGroup: "测评",
    lbGroupGroup: "团体测评",
    lbGroupOpen: "开放测评",
    lbGroupIndv: "个人测评",
    addlbGroupIndv: "新建个人测评",
    applymentmanage: "申请单管理",
    lbInfo: "量表",
    lbInfoManage: "量表设置",
    lbInfoAlert: "因子预警",
    lbInfoDocs: "量表文档",
    lbGroupPattern: "量表套餐",
    appointPages: "预约",
    doAppoint: "预约管理",
    processAppoint: "待处理的预约",
    appointHistory: "预约处理历史",
    taskPages: "任务",
    processTask: "接收的任务",
    createdTask: "创建的任务",
    settings: "设置",
    lbDefaultTemp: "默认设置",
    doctorTitle: "施测者"
  };
  if (isClientTypeHospital()) {
    defaultNaviTitle.personList = "被试患者";
    defaultNaviTitle.node = "测评科室";
    defaultNaviTitle.lbGroupIndv = "申请单 - 进行中";
    defaultNaviTitle.applymentmanage = "申请单 - 未处理";
  }
  if (isClientTypeSchool()) {
    defaultNaviTitle.personList = "被试学生";
    defaultNaviTitle.node = "班级分组";
    defaultNaviTitle.lbGroupGroup = "学生团体测评";
    defaultNaviTitle.lbGroupOpen = "学生自助测评";
    defaultNaviTitle.lbGroupIndv = "学生个人测评";
    defaultNaviTitle.doctorTitle = "心理老师";
  }
  if (isClientTypeJudicial()) {
    defaultNaviTitle.caseList = "评定案例";
    defaultNaviTitle.personList = "被试人员";
    defaultNaviTitle.node = "鉴定单位";
    defaultNaviTitle.lbGroup = "评定";
    defaultNaviTitle.lbGroupIndv = "正在进行";
    defaultNaviTitle.addlbGroupIndv = "新建评定";
    defaultNaviTitle.doctorTitle = "鉴定员";
  }
  return defaultNaviTitle;
}

export function getDefaultNaviIcon() {
  let defaultNaviIcon = {
    chartBox: "mdi-chart-box",
    caseList: "mdi-text-box-search",
    personList: "mdi-account-multiple",
    node: "mdi-layers",
    lbGroup: "mdi-pencil-box-multiple",
    lbGroupGroup: "mdi-account-multiple-outline",
    lbGroupOpen: "mdi-earth",
    lbGroupIndv: "mdi-account-outline",
    addlbGroupIndv: "mdi-account-plus-outline",
    applymentmanage: "mdi-file-cabinet",
    lbInfo: "mdi-clipboard-text-multiple-outline",
    lbInfoManage: "mdi-card-bulleted-settings-outline",
    lbInfoAlert: "mdi-alert-plus-outline",
    lbInfoDocs: "mdi-file-search-outline",
    lbGroupPattern: "mdi-briefcase-variant-outline",
    appointPages: "mdi-calendar-month",
    doAppoint: "mdi-calendar-month-outline",
    processAppoint: "mdi-calendar-multiple-check",
    appointHistory: "mdi-calendar-clock-outline",
    taskPages: "mdi-form-select",
    processTask: "mdi-folder-multiple-outline",
    createdTask: "mdi-folder-multiple-outline",
    settings: "mdi-cog"
  };
  if (isClientTypeJudicial()) {
    defaultNaviIcon.lbGroupIndv = "mdi-pencil-box-multiple";
  }
  return defaultNaviIcon;
}

export default {
  namespaced: true,
  state: {
    regionGuid: "",
    dispName: "",
    userGuid: "",
    userName: "",
    naviTitle: {},
    naviIcon: {},
    loginNodeGuids: [],
    isSuperNode: false,
    roleGuid: "",
    permissions: [],
    customerUrl: "",
    customerName: "",
    useHis: false
  },

  getters: {
    customerName(state) {
      return state.customerName || sessionStorage.getItem("customerName") || "";
    },
    customerUrl(state) {
      return state.customerUrl || sessionStorage.getItem("customerUrl") || "";
    },
    regionGuid(state) {
      return state.regionGuid || sessionStorage.getItem("regionGuid") || "";
    },
    regionDispName(state) {
      return state.dispName || sessionStorage.getItem("regionDispName") || "";
    },
    userGuid(state) {
      return state.userGuid || sessionStorage.getItem("userGuid") || "";
    },
    naviTitle(state) {
      let storedNaviTitle = state.naviTitle;
      if (!storedNaviTitle || Object.keys(storedNaviTitle).length < 1) {
        let sessionNaviTitle = sessionStorage.getItem("naviTitle");
        storedNaviTitle = sessionNaviTitle ? JSON.parse(sessionNaviTitle) : {};
      }
      // 只替换修改过的部分
      return Object.assign({ ...getDefaultNaviTitle() }, storedNaviTitle);
    },
    naviIcon(state) {
      let storedNaviIcon = state.naviIcon;
      if (!storedNaviIcon || Object.keys(storedNaviIcon).length < 1) {
        let sessionNaviIcon = sessionStorage.getItem("naviIcon");
        storedNaviIcon = sessionNaviIcon ? JSON.parse(sessionNaviIcon) : {};
      }
      // 只替换修改过的部分
      return Object.assign({ ...getDefaultNaviIcon() }, storedNaviIcon);
    },
    loginNodeGuids(state) {
      if (!state.loginNodeGuids || state.loginNodeGuids.length < 1) {
        var storedNodeGuids = sessionStorage.getItem("nodeGuids");
        return storedNodeGuids ? JSON.parse(storedNodeGuids) : [];
      }
      return state.loginNodeGuids;
    },
    userName(state) {
      return state.userName || sessionStorage.getItem("userName") || "";
    },
    isSuperNode(state) {
      return state.isSuperNode || false;
    },
    roleGuid(state) {
      return state.roleGuid || "";
    },
    permissions(state) {
      return state.permissions || [];
    },
    useHis(state) {
      return JSON.parse(
        state.useHis || sessionStorage.getItem("useHis") || "false"
      );
    }
  },

  actions: {
    setLoginInfo(
      { commit },
      {
        regionGuid,
        userGuid,
        userName,
        loginNodeGuids,
        customerUrl,
        customerName,
        useHis
      }
    ) {
      commit("SET_REGION_GUID", regionGuid);
      commit("SET_USER_GUID", userGuid);
      commit("SET_USER_NAME", userName);
      commit("SET_LOGIN_NODE_GUIDS", loginNodeGuids || []);
      commit("SET_CUSTOMER_URL", customerUrl);
      commit("SET_CUSTOMER_NAME", customerName);
      commit("SET_USE_HIS", useHis);
    },
    clearLoginInfo({ commit }) {
      commit("SET_REGION_GUID", null);
      commit("SET_REGION_DISP_NAME", null);
      commit("SET_USER_GUID", null);
      commit("SET_USER_NAME", null);
      commit("SET_LOGIN_NODE_GUIDS", []);
      commit("SET_ROLE_GUID", null);
      commit("SET_PERMISSIONS", []);
      commit("SET_CUSTOMER_URL", null);
      commit("SET_CUSTOMER_NAME", null);
      commit("SET_USE_HIS", false);
    },
    setRegionDispName({ commit }, dispName) {
      commit("SET_REGION_DISP_NAME", dispName);
    },
    setNaviTitle({ commit }, naviTitle) {
      commit("SET_NAVI_TITLE", naviTitle);
    },
    setNaviIcon({ commit }, naviIcon) {
      commit("SET_NAVI_ICON", naviIcon);
    },
    setUserName({ commit }, userName) {
      commit("SET_USER_NAME", userName);
    },
    async reloadUserRoles({ commit, getters }) {
      try {
        let userRole = await getRolePermissionFromUser(getters.userGuid);
        let permissionNames =
          !!userRole.permissions && userRole.permissions.length > 0
            ? userRole.permissions.map(p => p.name)
            : [];
        commit("SET_IS_SUPER_NODE", userRole.isSuper);
        commit("SET_ROLE_GUID", userRole.guid);
        commit("SET_PERMISSIONS", permissionNames);
        return permissionNames;
      } catch {
        throw "获取用户权限失败，请联系管理员设置账号权限";
      }
    }
  },

  mutations: {
    SET_CUSTOMER_NAME(state, customerName) {
      state.customerName = customerName;
      sessionStorage.setItem("customerName", customerName || "");
    },
    SET_CUSTOMER_URL(state, customerUrl) {
      state.customerUrl = customerUrl;
      sessionStorage.setItem("customerUrl", customerUrl || "");
    },
    SET_REGION_GUID(state, regionGuid) {
      state.regionGuid = regionGuid;
      sessionStorage.setItem("regionGuid", regionGuid || "");
    },
    SET_REGION_DISP_NAME(state, dispName) {
      state.dispName = dispName;
      sessionStorage.setItem("regionDispName", dispName || "");
    },
    SET_USER_GUID(state, userGuid) {
      state.userGuid = userGuid;
      sessionStorage.setItem("userGuid", userGuid || "");
    },
    SET_USER_NAME(state, userName) {
      state.userName = userName;
      sessionStorage.setItem("userName", userName || "");
    },
    SET_NAVI_TITLE(state, naviTitle) {
      state.naviTitle = naviTitle;
      sessionStorage.setItem("naviTitle", JSON.stringify(naviTitle));
    },
    SET_NAVI_ICON(state, naviIcon) {
      state.naviIcon = naviIcon;
      sessionStorage.setItem("naviIcon", JSON.stringify(naviIcon));
    },
    SET_LOGIN_NODE_GUIDS(state, loginNodeGuids) {
      state.loginNodeGuids = [...loginNodeGuids];
      sessionStorage.setItem("nodeGuids", JSON.stringify(loginNodeGuids));
    },
    SET_IS_SUPER_NODE(state, isSuperNode) {
      state.isSuperNode = isSuperNode;
    },
    SET_ROLE_GUID(state, roleGuid) {
      state.roleGuid = roleGuid;
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },
    SET_USE_HIS(state, useHis) {
      state.useHis = useHis;
      sessionStorage.setItem("useHis", useHis || "false");
    }
  }
};
