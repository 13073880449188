export function buildUrlString(...urlSegments) {
  if (urlSegments && urlSegments.length > 0) {
    urlSegments = urlSegments.map(urlSeg => {
      if (urlSeg.startsWith("/") || urlSeg.startsWith("\\")) {
        urlSeg = urlSeg.slice(1);
      }
      if (urlSeg.endsWith("/") || urlSeg.endsWith("\\")) {
        urlSeg = urlSeg.slice(0, -1);
      }
      return urlSeg;
    });
    return urlSegments.join("/");
  }
  return "";
}
