import { permissionNames } from "@/utils/constants/permissions";
import store from "@/store";
import { isClientTypeHospital, isClientTypeJudicial } from "@/utils/appType";

export const notFountRoute = {
  path: "*",
  name: "nopage",
  meta: {
    type: "nopage",
    title: "对不起，您没有权限进行这个操作"
  },
  component: () => import(/* webpackChunkName: "nopage" */ "@/views/NoPage.vue")
};

export const staticRoutes = [
  {
    path: "/",
    name: "login",
    props: route => ({
      autoLoginSerialNum: route.query.st,
      autoLoginUserName: route.query.userID
    }),
    meta: {
      type: "adminlogin",
      title: "登录"
    },
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue")
  },
  {
    path: "/noreg",
    name: "noreg",
    props: true,
    meta: {
      type: "noreg",
      title: "未授权"
    },
    component: () => import(/* webpackChunkName: "noreg" */ "@/views/NoReg.vue")
  },
  {
    path: "/errorpage",
    name: "errorpage",
    props: true,
    meta: {
      type: "errorpage",
      title: "页面错误"
    },
    component: () =>
      import(/* webpackChunkName: "errorpage" */ "@/views/ErrorPage.vue")
  },
  {
    path: "/:customerUrl?/lgscreen",
    name: "lgscreen",
    props: true,
    meta: {
      type: "lgscreen",
      title: "大屏展示",
      perms: [permissionNames.dashboard.largeScreen]
    },
    component: () =>
      import(
        /* webpackChunkName: "lgscreen" */ "@/views/chart/LargeScreenPage.vue"
      )
  },
  {
    path: "/:customerUrl",
    name: "customerlogin",
    meta: {
      type: "adminlogin",
      title: "登录"
    },
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue")
  },
  {
    path: "/:customerUrl?/reportviewer",
    name: "reportviewer",
    props: route => ({
      caseGuid: route.query.caseguid
    }),
    meta: {
      type: "reportviewer",
      title: "查看报告"
    },
    component: () =>
      import(
        /* webpackChunkName: "reportviewer" */ "@/components/report/ReportViewer.vue"
      )
  }
];

export function buildDynamicRoutes() {
  let baseAdminRoute = {
    path: "/admin",
    name: "admin",
    meta: {
      type: "admin"
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/admin/Admin.vue"),
    children: []
  };
  // ===================== 数据看板 =====================
  baseAdminRoute.children.push($_buildChartRoute());
  // ===================== 案例 =====================
  baseAdminRoute.children.push($_buildCaseListRoute());
  // ===================== 被试人 =====================
  baseAdminRoute.children.push($_buildTestPersonRoute());
  // ===================== 单位 =====================
  baseAdminRoute.children.push($_buildManageNodeRoute());
  // ===================== 测评 =====================
  baseAdminRoute.children.push($_buildLbGroupRoute());
  // ===================== 量表 =====================
  baseAdminRoute.children.push($_buildLbRoute());
  // ===================== 预约 =====================
  if (!isClientTypeJudicial()) {
    baseAdminRoute.children.push($_buildAppointmentRoute());
  }
  // ===================== 任务 =====================
  // TODO: 任务部分暂不添加，之后需要的时候再加
  // baseAdminRoute.children.push($_buildTaskRoute());
  // ===================== 设置 =====================
  baseAdminRoute.children.push($_buildAdminSettingsRoute());

  return [baseAdminRoute];
}

function $_buildChartRoute() {
  return {
    path: "chart",
    name: "chart",
    meta: {
      type: "admin",
      navi: {
        iconName: () => store.getters["user/naviIcon"].chartBox,
        title: () => store.getters["user/naviTitle"].chartBox
      },
      perms: [permissionNames.dashboard.read]
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */
        "@/views/admin/AdminChart.vue"
      )
  };
}

function $_buildCaseListRoute() {
  return {
    path: "report",
    name: "report",
    meta: {
      type: "admin",
      navi: {
        iconName: store.getters["user/naviIcon"].caseList,
        title: () => store.getters["user/naviTitle"].caseList
      },
      perms: [permissionNames.case.read]
    },
    component: () =>
      import(
        /* webpackChunkName: "report" */
        "@/views/admin/AdminCaseReport.vue"
      )
  };
}

function $_buildTestPersonRoute() {
  return {
    path: "testperson",
    name: "testperson",
    meta: {
      type: "admin",
      navi: {
        iconName: () => store.getters["user/naviIcon"].personList,
        title: () => store.getters["user/naviTitle"].personList
      },
      perms: [permissionNames.person.read]
    },
    component: () =>
      import(
        /* webpackChunkName: "testperson" */
        "@/views/admin/AdminTestPerson.vue"
      )
  };
}

function $_buildManageNodeRoute() {
  return {
    path: "node",
    name: "confignode",
    meta: {
      type: "admin",
      navi: {
        iconName: () => store.getters["user/naviIcon"].node,
        title: () => store.getters["user/naviTitle"].node
      },
      perms: [permissionNames.node.read]
    },
    component: () =>
      import(
        /* webpackChunkName: "confignode" */
        "@/views/admin/AdminConfigNode.vue"
      )
  };
}

function $_buildLbGroupRoute() {
  let lbGroupRoute = {
    path: "lbgroup",
    name: "lbgroupbase",
    meta: {
      type: "admin",
      navi: {
        iconName: () => store.getters["user/naviIcon"].lbGroup,
        title: () => store.getters["user/naviTitle"].lbGroup
      },
      perms: [
        permissionNames.sca.group.read,
        permissionNames.sca.open.read,
        permissionNames.sca.indv.read
      ]
    },
    component: () =>
      import(
        /* webpackChunkName: "lbgroupbase" */
        "@/views/admin/AdminConfig.vue"
      ),
    children: []
  };
  if (!isClientTypeHospital() && !isClientTypeJudicial()) {
    // 团体测评（医院版不包含、司法鉴定不包含）
    lbGroupRoute.children.push({
      path: "grouplbgroup",
      name: "grouplbgroup",
      props: () => ({ type: "group" }),
      meta: {
        type: "admin",
        navi: {
          iconName: () => store.getters["user/naviIcon"].lbGroupGroup,
          title: () => store.getters["user/naviTitle"].lbGroupGroup
        },
        perms: [permissionNames.sca.group.read]
      },
      component: () =>
        import(
          /* webpackChunkName: "grouplbgroup" */
          "@/views/admin/AdminLbGroup.vue"
        )
    });
  }
  // 开放测评
  lbGroupRoute.children.push({
    path: "openlbgroup",
    name: "openlbgroup",
    props: () => ({ type: "open" }),
    meta: {
      type: "admin",
      navi: {
        iconName: () => store.getters["user/naviIcon"].lbGroupOpen,
        title: () => store.getters["user/naviTitle"].lbGroupOpen
      },
      perms: [permissionNames.sca.open.read]
    },
    component: () =>
      import(
        /* webpackChunkName: "openlbgroup" */
        "@/views/admin/AdminLbGroup.vue"
      )
  });
  // 个人测评

  lbGroupRoute.children.push({
    path: "individuallbgroup",
    name: "individuallbgroup",
    props: () => ({ type: "individual" }),
    meta: {
      type: "admin",
      navi: {
        iconName: () => store.getters["user/naviIcon"].lbGroupIndv,
        title: () => store.getters["user/naviTitle"].lbGroupIndv
      },
      perms: [permissionNames.sca.indv.read]
    },
    component: () =>
      import(
        /* webpackChunkName: "individuallbgroup" */
        "@/views/admin/AdminLbGroup.vue"
      )
  });
  if (isClientTypeJudicial()) {
    lbGroupRoute.children.push({
      path: "addindividuallbgroup",
      name: "addindividuallbgroup",
      meta: {
        type: "admin",
        navi: {
          iconName: () => store.getters["user/naviIcon"].addlbGroupIndv,
          title: () => store.getters["user/naviTitle"].addlbGroupIndv
        },
        perms: [permissionNames.sca.indv.read]
      },
      component: () =>
        import(
          /* webpackChunkName: "addindividuallbgroup" */
          "@/views/admin/AdminJudicialIdvLbGroup.vue"
        )
    });
  }
  // HIS 申请单列表（只有医院有）
  if (isClientTypeHospital()) {
    lbGroupRoute.children.push({
      path: "applymentmanage",
      name: "applymentmanage",
      meta: {
        type: "admin",
        navi: {
          iconName: () => store.getters["user/naviIcon"].applymentmanage,
          title: () => store.getters["user/naviTitle"].applymentmanage
        },
        perms: [permissionNames.sca.apply.read]
      },
      component: () =>
        import(
          /* webpackChunkName: "applymentindividuallbgroup" */
          "@/views/admin/AdminApplymentManage.vue"
        )
    });
  }
  return lbGroupRoute;
}

function $_buildLbRoute() {
  let lbRoute = {
    path: "lb",
    name: "lbmanage",
    meta: {
      type: "admin",
      navi: {
        iconName: () => store.getters["user/naviIcon"].lbInfo,
        title: () => store.getters["user/naviTitle"].lbInfo
      },
      perms: [
        permissionNames.lb.settings.read,
        permissionNames.lb.alert.read,
        permissionNames.lb.doc.read,
        permissionNames.lb.groupPattern.read
      ]
    },
    component: () =>
      import(
        /* webpackChunkName: "lbmanage" */
        "@/views/admin/AdminConfig.vue"
      ),
    children: []
  };
  // 量表信息设置
  lbRoute.children.push({
    path: "lbinfomanage",
    name: "lbinfomanage",
    meta: {
      type: "admin",
      navi: {
        iconName: () => store.getters["user/naviIcon"].lbInfoManage,
        title: () => store.getters["user/naviTitle"].lbInfoManage
      },
      perms: [permissionNames.lb.settings.read]
    },
    component: () =>
      import(
        /* webpackChunkName: "lbinfomanage" */
        "@/views/admin/AdminLbInfoManage.vue"
      )
  });
  if (!isClientTypeJudicial()) {
    // 量表预警设置
    lbRoute.children.push({
      path: "lbwaring",
      name: "lbwaring",
      meta: {
        type: "admin",
        navi: {
          iconName: () => store.getters["user/naviIcon"].lbInfoAlert,
          title: () => store.getters["user/naviTitle"].lbInfoAlert
        },
        perms: [permissionNames.lb.alert.read]
      },
      component: () =>
        import(
          /* webpackChunkName: "lbwaring" */
          "@/views/admin/AdminLbWaringManage.vue"
        )
    });
  }
  // 量表文档
  lbRoute.children.push({
    path: "lbdocs",
    name: "lbdocs",
    meta: {
      type: "admin",
      navi: {
        iconName: () => store.getters["user/naviIcon"].lbInfoDocs,
        title: () => store.getters["user/naviTitle"].lbInfoDocs
      },
      perms: [permissionNames.lb.doc.read]
    },
    component: () =>
      import(
        /* webpackChunkName: "lbdocs" */
        "@/views/admin/AdminLbDocs.vue"
      )
  });
  // 量表套餐管理
  lbRoute.children.push({
    path: "lbgrouppattern",
    name: "lbgrouppattern",
    meta: {
      type: "admin",
      navi: {
        iconName: () => store.getters["user/naviIcon"].lbGroupPattern,
        title: () => store.getters["user/naviTitle"].lbGroupPattern
      },
      perms: [permissionNames.lb.groupPattern.read]
    },
    component: () =>
      import(
        /* webpackChunkName: "lbgrouppattern" */
        "@/views/admin/AdminLbGroupPattern.vue"
      )
  });
  return lbRoute;
}

function $_buildAppointmentRoute() {
  let appointmentRoute = {
    path: "ap",
    name: "appointpages",
    meta: {
      type: "admin",
      navi: {
        iconName: () => store.getters["user/naviIcon"].appointPages,
        title: () => store.getters["user/naviTitle"].appointPages
      },
      perms: [permissionNames.appoint.read]
    },
    component: () =>
      import(
        /* webpackChunkName: "appointpages" */
        "@/views/admin/AdminConfig.vue"
      ),
    children: []
  };
  // 预约管理
  appointmentRoute.children.push({
    path: "doappoint",
    name: "doappoint",
    meta: {
      type: "admin",
      navi: {
        iconName: () => store.getters["user/naviIcon"].doAppoint,
        title: () => store.getters["user/naviTitle"].doAppoint
      },
      perms: [permissionNames.appoint.read]
    },
    component: () =>
      import(
        /* webpackChunkName: "doappoint" */
        "@/views/admin/AdminDoAppoint.vue"
      )
  });
  // 待处理的预约
  appointmentRoute.children.push({
    path: "processappoint",
    name: "processappoint",
    props: () => ({ type: "processing" }),
    meta: {
      type: "admin",
      navi: {
        iconName: () => store.getters["user/naviIcon"].processAppoint,
        title: () => store.getters["user/naviTitle"].processAppoint
      },
      perms: [permissionNames.appoint.read]
    },
    component: () =>
      import(
        /* webpackChunkName: "processappoint" */
        "@/views/admin/AdminAppointment.vue"
      )
  });
  // 预约历史
  appointmentRoute.children.push({
    path: "appointhistory",
    name: "appointhistory",
    props: () => ({ type: "finished" }),
    meta: {
      type: "admin",
      navi: {
        iconName: () => store.getters["user/naviIcon"].appointHistory,
        title: () => store.getters["user/naviTitle"].appointHistory
      },
      perms: [permissionNames.appoint.read]
    },
    component: () =>
      import(
        /* webpackChunkName: "appointhistory" */
        "@/views/admin/AdminAppointment.vue"
      )
  });
  return appointmentRoute;
}

function $_buildTaskRoute() {
  let taskRoute = {
    path: "task",
    name: "taskpages",
    meta: {
      type: "admin",
      navi: {
        iconName: () => store.getters["user/naviIcon"].taskPages,
        title: () => store.getters["user/naviTitle"].taskPages
      },
      perms: [permissionNames.task.read]
    },
    component: () =>
      import(
        /* webpackChunkName: "taskpages" */
        "@/views/admin/AdminConfig.vue"
      ),
    children: []
  };
  // 接收的任务
  taskRoute.children.push({
    path: "processtask",
    name: "processtask",
    props: () => ({ type: "process" }),
    meta: {
      type: "admin",
      navi: {
        iconName: () => store.getters["user/naviIcon"].processTask,
        title: () => store.getters["user/naviTitle"].processTask
      },
      perms: [permissionNames.task.read]
    },
    component: () =>
      import(
        /* webpackChunkName: "processtask" */
        "@/views/admin/AdminTaskManage.vue"
      )
  });
  // 创建的任务
  taskRoute.children.push({
    path: "mycreatetask",
    name: "mycreatetask",
    props: () => ({ type: "created" }),
    meta: {
      type: "admin",
      navi: {
        iconName: () => store.getters["user/naviIcon"].createdTask,
        title: () => store.getters["user/naviTitle"].createdTask
      },
      perms: [permissionNames.task.read]
    },
    component: () =>
      import(
        /* webpackChunkName: "mycreatetask" */
        "@/views/admin/AdminTaskManage.vue"
      )
  });
  return taskRoute;
}

function $_buildAdminSettingsRoute() {
  return {
    path: "settings",
    name: "settings",
    meta: {
      type: "admin",
      navi: {
        iconName: () => store.getters["user/naviIcon"].settings,
        title: () => store.getters["user/naviTitle"].settings
      },
      perms: [
        permissionNames.settings.login.user.read,
        permissionNames.settings.login.role.read,
        permissionNames.settings.display.edit,
        permissionNames.settings.userAccount.edit,
        permissionNames.case.rpt.sign
      ]
    },
    component: () =>
      import(
        /* webpackChunkName: "settings" */
        "@/views/admin/AdminSettings.vue"
      )
  };
}
