const { clientTypeDict } = require("./constants/clientType");

exports.isBrowserApp = () => {
  return process.env.VUE_APP_TARGET_TYPE === "browser";
};

exports.isDesktopApp = () => {
  return (
    process.env.VUE_APP_TARGET_TYPE === "win" ||
    process.env.VUE_APP_TARGET_TYPE === "mac"
  );
};

exports.isDesktopWindowsApp = () => {
  return process.env.VUE_APP_TARGET_TYPE === "win";
};

exports.isDesktopMacOsApp = () => {
  return process.env.VUE_APP_TARGET_TYPE === "mac";
};

exports.isMobileApp = () => {
  return (
    process.env.VUE_APP_TARGET_TYPE === "android" ||
    process.env.VUE_APP_TARGET_TYPE === "ios"
  );
};

exports.isMobileAndroidApp = () => {
  return process.env.VUE_APP_TARGET_TYPE === "android";
};

exports.isMobileIosApp = () => {
  return process.env.VUE_APP_TARGET_TYPE === "ios";
};

exports.isClientTypeHospital = () => {
  return process.env.VUE_APP_CLIENT_TYPE === clientTypeDict.hospital.value;
};

exports.isClientTypeSchool = () => {
  return process.env.VUE_APP_CLIENT_TYPE === clientTypeDict.school.value;
};

exports.isClientTypeJudicial = () => {
  return process.env.VUE_APP_CLIENT_TYPE === clientTypeDict.judicial.value;
};
